.breadcrumb {
  color: #757373;
  font-weight: bold;
  font-size: theme("fontSize.xs");
  li{
    display: inline;
  }
  .breadcrumb__item {
    &::before {
      content: "-";
      margin: 0 theme("margin.2");
      width: 4px;
      height: 4px;
    }
  }

  a{
    &:hover{
      opacity: 0.6;
      transition-property: opacity;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 300ms;
    }
  }
}
